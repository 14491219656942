import { routeUrls } from 'root/src/shared/descriptions/routes/routeUrls'
import router from 'next/router'
import changeRoute from 'root/src/client/logic/route/actions/changeRoute'

export default item => async (dispatch) => {
	await router.push(routeUrls.addCampaign({
		dropId: item.id,
	}))
	dispatch(changeRoute({ routeId: undefined }))
}
