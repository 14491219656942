import { UPDATE_CAMPAIGN_TERMS_ACCEPTED } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
export const updateCampaignTermsAcceptedEndpoint = {
    [UPDATE_CAMPAIGN_TERMS_ACCEPTED]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.admin,
        payloadSchema: {
            type: 'object',
            properties: {
                dropId: { type: 'string' },
                creatorId: { type: 'string' },
                update: {
                    type: 'object',
                    properties: {
                        estimatedPointsToEarn: { type: 'number' },
                        reservedPointsLeft: { type: 'number' },
                        reservedPointsTotal: { type: 'number' },
                        pointsReservationDueDate: { type: 'string' },
                    },
                },
            },
            required: ['dropId', 'creatorId'],
        },
    },
};
