import sub from 'root/src/aws/util/sub'
import { GATEWAY_REST_API } from 'root/src/aws/gateway/resourceIds'

export const REST_STAGENAME = 'v1'
export const WEBSOCKETS_STAGENAME = 'v1'

export const GATEWAY_URL_OUTPUT_SUB = sub(
	`https://\${${GATEWAY_REST_API}}.execute-api.\${AWS::Region}.amazonaws.com/${REST_STAGENAME}/`,
)

export const DEFAULT_LAMBDA_TIMEOUT = 25

/**
 * The default timeout for lambda handlers, which is the lambda timeout minus 2 seconds to allow for the handler to save logs and return a response.
 * @type {number}
 */
export const DEFAULT_LAMBDA_HANDLER_TIMEOUT =	DEFAULT_LAMBDA_TIMEOUT - 2
