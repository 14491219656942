module.exports = {
	AWS_STACK_DOMAIN: 'mvcewicz.link',
	AWS_ACCOUNT_ID: '796845539696',
	AWS_DEV_EMAIL: 'macewicz.wojciech+support@gmail.com',
	AWS_REGION: 'us-east-1',
	awsHostedZoneId: 'Z07517953I76I628YKQVO',
	awsAuthApiId: 'n35rsfl9o1',
	twitchClientId: 'st716o64z2x6jnv3ugwhwzm0lngxrn',
	paypalClientId: 'ATf9XO4BE9GVtSU-U66RME-cdvThMmunjV7jdgL3baL-xMbTsjoDvZKji_YkVzF71T4rOqhuZ6uKCcq4',
	youtubeApiKeyClient: 'AIzaSyCQKWSxt7CelvTYnBDNqTEBy_N3Zv6VazI',
	secretArns: [
		'arn:aws:secretsmanager:us-east-1:796845539696:secret:PayPal_Test-gLBXeT',
		'arn:aws:secretsmanager:us-east-1:796845539696:secret:developmentTwitchSecret-QxvCMB',
		'arn:aws:secretsmanager:us-east-1:796845539696:secret:developmentYoutubeOAuth-t6tceg',
		'arn:aws:secretsmanager:us-east-1:796845539696:secret:developmentGoogle-9tsQoI',
		'arn:aws:secretsmanager:us-east-1:796845539696:secret:tiktokOauthSecretDev-mxsaR3',
		'arn:aws:secretsmanager:us-east-1:796845539696:secret:paypalApiCertificateDev-HPHH59',
		'arn:aws:secretsmanager:us-east-1:796845539696:secret:cryptoDevelopment-C7lqjw',
		'arn:aws:secretsmanager:us-east-1:796845539696:secret:developmentYoutubeOAuth-t6tceg',
		'arn:aws:secretsmanager:us-east-1:796845539696:secret:developmentGoogle-9tsQoI',
		'arn:aws:secretsmanager:us-east-1:796845539696:secret:twitchOverlaySecret-sR3EHL',
		'arn:aws:secretsmanager:us-east-1:796845539696:secret:twitchPanelSecret-mgZhlX',
		'arn:aws:secretsmanager:us-east-1:918224642237:secret:githubSecrets-8osoap',
		'arn:aws:secretsmanager:us-east-1:796845539696:secret:steamWebAPI-EK0UIi',
		'arn:aws:secretsmanager:us-east-1:796845539696:secret:steamWebAPISecrets-E4QlwU',
	],
}
