/* eslint-disable import/no-cycle */
import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'
import moduleEndpointIdSelector from 'root/src/client/logic/api/selectors/moduleEndpointIdSelector'
import moduleRecordPayloadMapSelector from 'root/src/client/logic/api/selectors/moduleRecordPayloadMapSelector'
import subObj from 'root/src/shared/util/subObj'
import getRecordIdMod from 'root/src/client/logic/route/util/getRecordIdMod'
import moduleDescriptions from 'root/src/shared/descriptions/modules'
import { mapP } from 'root/src/shared/util/ramdaPlus'

export default ({ moduleId, nextRouteObj }) => async (dispatch) => {
	const endpointId = moduleEndpointIdSelector({ /* state */ }, { moduleId })
	const { routeId, routeParams = {}, accessToken } = nextRouteObj
	const { recordId } = routeParams

	const payloadSubs = {
		recordId: getRecordIdMod({ routeId, recordId }),
	}

	const payloadItems = moduleRecordPayloadMapSelector(
		{ /* state */ }, { moduleId },
	)
	const payload = payloadItems ? subObj(payloadSubs, payloadItems) : {}

	if (endpointId) {
		return Array.isArray(endpointId)
			? mapP(
				id => dispatch(apiRequest(id, payload, false, moduleId, moduleDescriptions, false, accessToken)),
				endpointId,
			)
			: dispatch(apiRequest(endpointId, payload, false, moduleId, moduleDescriptions, false, accessToken))
	}

	return null
}
