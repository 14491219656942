export const obsCloudfrontDistributionArn = 'E2SC8KPY1N0KRI'
export const mixpanelProxyApiId = 'zl7vr27l3f'
export const apiLambdaLowSpecArn = 'arn:aws:lambda:us-east-1:796845539696:function:dareMemvcewiczDev-dareMemvcewiczDevCloudwatchLowSp-yyY2T3t4ej0T'
export const cloudWatchLogsReportErrorGroupName = 'dareMemvcewiczDev-dareMemvcewiczDevReportErrorGroup-cARhiO5GsCNI'
export const nextOriginFunctionName = 'dareMemvcewiczDev-dareMemvcewiczDevNextOriginReque-618JRE4ZBjSt'
export const performanceTestDynamoDBTableArn = 'arn:aws:dynamodb:us-east-1:796845539696:table/dareMemvcewiczDev-dareMemvcewiczDevPerformanceTestDynamoDbTable-1NN5LIEHNDP7C'
export const lambdaSqsFifoQueueDlqUrl = 'https://sqs.us-east-1.amazonaws.com/796845539696/dareMemvcewiczDev-dareMemvcewiczDevLambdaSqsFifoQueueDlq-MWWNq6kdtxG8.fifo'
export const deliveryUploadsQueueUrl = 'https://sqs.us-east-1.amazonaws.com/796845539696/PythonTasksStackDev-DeliveryUloadsQueue02BA1BE6-VIj53Zv8z6tB'
export const cloudWatchLogsInfoGroupName = 'dareMemvcewiczDev-dareMemvcewiczDevInfoLogGroup-y7pftXvITruj'
export const apiCloudwatchFunctionArn = 'arn:aws:lambda:us-east-1:796845539696:function:dareMemvcewiczDev-dareMemvcewiczDevApiLambdaCloudW-TjnlAxU2epWB'
export const dataBucket = 'darememvcewiczdev-darememvcewiczdevdatabucket-1fs462u8f4w9m'
export const dynamoLockTableName = 'arn:aws:dynamodb:us-east-1:796845539696:table/dareMemvcewiczDev-dareMemvcewiczDevLockTable-1UAJQIOZNQHXH'
export const staticBucket = 'darememvcewiczdev-darememvcewiczdevstaticbucket-1x1cjutihi270'
export const cloudfrontDistributionArn = 'E3B1XUY5DKT2FL'
export const performanceTestDynamoDBDataTableArn = 'arn:aws:dynamodb:us-east-1:796845539696:table/dareMemvcewiczDev-dareMemvcewiczDevPerformanceTestDynamoDbDataTable-1XQ9GAWPR3BBD'
export const cloudWatchLogsEsGroupName = 'dareMemvcewiczDev-dareMemvcewiczDevEsLogGroup-QqSRoJWiAWsh'
export const clientId = '7r9tblin10bf8l8pj029bucibd'
export const elasticsearchDomainEndpoint = 'search-daremem-dareme-d0vxlajoyr89-5cgk6njapoy7y3z6i5rj5rok7a.us-east-1.es.amazonaws.com'
export const apiDynamoWebsocketsConnectionsTable = 'arn:aws:dynamodb:us-east-1:796845539696:table/dareMemvcewiczDev-dareMemvcewiczDevApiDynamoDbWebsocketConnectionsTable-12JE3V20W0B1F'
export const cloudwatchLogsOutput = 'darememvcewiczdev-darememvcewiczdevcloudwatchlogs-1x7ie14f6iswq'
export const apiDynamoArchivalTableName = 'arn:aws:dynamodb:us-east-1:796845539696:table/dareMemvcewiczDev-dareMemvcewiczDevApiDynamoDbArchivalTable-1MJQA6T8ADQSQ'
export const gatewayWebsocketApiId = '1j2rpbhg80'
export const performanceTestArchivalDynamoDBDataTableArn = 'arn:aws:dynamodb:us-east-1:796845539696:table/dareMemvcewiczDev-dareMemvcewiczDevPerformanceTestArchivalDynamoDbDataTable-1XMLOUZUX3ZUG'
export const apiDynamoScanTableName = 'arn:aws:dynamodb:us-east-1:796845539696:table/dareMemvcewiczDev-dareMemvcewiczDevApiDynamoDbScanTable-37TPR0JXJIN4'
export const lambdaSqsStandardQueueUrl = 'https://sqs.us-east-1.amazonaws.com/796845539696/dareMemvcewiczDev-dareMemvcewiczDevLambdaSqsStandardQueue-NCGwQb0s6Ilo'
export const lambdaSqsStandardQueueDlqUrl = 'https://sqs.us-east-1.amazonaws.com/796845539696/dareMemvcewiczDev-dareMemvcewiczDevLambdaSqsStandardQueueDlq-XuPIMtp46v9x'
export const sesConfigurationSetEventBasedEmailsArn = 'dareMemvcewiczDevSESConfigurationSetEventBasedEmail-BIdKGQ9UZlEw'
export const apiDynamoTableName = 'arn:aws:dynamodb:us-east-1:796845539696:table/dareMemvcewiczDev-dareMemvcewiczDevApiDynamoDbTable-ZYY3G6KFFSAY'
export const gatewaySentryTunnelApiId = '20m9m8vgub'
export const cloudWatchLogsEmailGroupName = 'dareMemvcewiczDev-dareMemvcewiczDevEmailLogGroup-g6diSf2L7iBi'
export const apiFunctionArn = 'arn:aws:lambda:us-east-1:796845539696:function:dareMemvcewiczDev-dareMemvcewiczDevTriggerLambdaFu-trGtYPsoXNWu'
export const fileUploadBucket = 'darememvcewiczdev-darememvcewiczdevfileuploadbuck-hisvi92brykb'
export const domainName = 'dev.mvcewicz.link'
export const seedDbBucket = 'darememvcewiczdev-darememvcewiczdevseeddbbucket-16zcocgupd2zp'
export const kmsCustomerMasterKeyArn = 'arn:aws:kms:us-east-1:796845539696:key/e510cdb3-2115-4ddc-8bf8-ea40431a3a9f'
export const apiDynamoStatisticsTableName = 'arn:aws:dynamodb:us-east-1:796845539696:table/dareMemvcewiczDev-dareMemvcewiczDevApiDynamoDbStatisticsTable-XRX5Q8KSOCN6'
export const gatewayLambdaArn = 'arn:aws:lambda:us-east-1:796845539696:function:dareMemvcewiczDev-dareMemvcewiczDevGatewayLambda-bDCR5qoI2M7S'
export const lambdaSqsFifoQueueUrl = 'https://sqs.us-east-1.amazonaws.com/796845539696/dareMemvcewiczDev-dareMemvcewiczDevLambdaSqsFifoQueue-YdxMdpm9g2ux.fifo'
export const userPoolId = 'us-east-1_SJcDDvBTo'
export const identityPoolId = 'us-east-1:be4310ab-e299-4d6f-b2f1-ea098768220a'
export const cloudWatchEventsIamRole = 'arn:aws:iam::796845539696:role/dareMemvcewiczDev-dareMemvcewiczDevCloudwatchEvent-1LPV6P1EXMXZ0'