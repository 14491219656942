import { GET_VERIFICATIONS } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { authenticationTypes, userGroups, } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { verificationStates } from 'root/src/server/modules/creators/verify/constants/verification.constants';
export const getVerificationsEndpoint = {
    [GET_VERIFICATIONS]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.admin,
        endpointType: otherEndpointType,
        payloadSchema: {
            type: 'object',
            properties: {
                userIds: {
                    type: 'array',
                    items: {
                        type: 'string',
                    },
                },
                state: {
                    type: 'string',
                    enum: Object.values(verificationStates),
                },
            },
            required: ['state'],
        },
    },
};
