import { LIST_CAMPAIGN_TERMS_ACCEPTED } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
export const listCampaignTermsAcceptedEndpoint = {
    [LIST_CAMPAIGN_TERMS_ACCEPTED]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.admin,
        payloadSchema: {
            type: 'object',
            properties: {
                dropId: { type: 'string' },
            },
        },
        required: ['dropId'],
    },
};
