import { ADMIN_BANNED_ACCOUNTS_LIST_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { tableListType } from 'root/src/shared/constants/moduleTypes'
import { creatorData } from 'root/src/shared/descriptions/endpoints/recordTypes'
import {
	ADMIN_GET_BANNED_ACCOUNTS,
	ADMIN_UNBAN_ACCOUNT,
	SET_VERIFICATION_STATE,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import pushRouteClient from 'root/src/client/logic/route/util/pushRouteClient'
import { profileRouteMap } from 'root/src/shared/constants/route'
import { renderHtmlThreats } from 'root/src/shared/util/ipData'
import { verificationStates } from 'root/src/server/modules/creators/verify/constants/verification.constants'

export default {
	[ADMIN_BANNED_ACCOUNTS_LIST_MODULE_ID]: {
		moduleType: 'list',
		listType: tableListType,
		tableColumns: [
			['Name', 'displayName'],
			['Platform', 'platform'],
			['Email', 'email'],
			['Threats', 'threats', renderHtmlThreats],
		],
		tableItemActions: [
			{
				name: 'Profile',
				fn: item => () => pushRouteClient(
					{ routeId: profileRouteMap[item.platform], routeParams: { recordId: item.mnemonicId } },
				),
			},
			{
				name: 'Reject',
				fn: (item, { apiRequest }) => async (dispatch) => {
					await dispatch(apiRequest(SET_VERIFICATION_STATE, {
						...item,
						state: verificationStates.VERIFICATION_REJECTED,
					}))
					return dispatch(
						apiRequest(ADMIN_GET_BANNED_ACCOUNTS, {}, true, ADMIN_BANNED_ACCOUNTS_LIST_MODULE_ID),
					)
				},
			},
			{
				name: 'Unban',
				fn: (item, { apiRequest }) => async (dispatch) => {
					await dispatch(apiRequest(ADMIN_UNBAN_ACCOUNT, { userId: item.userId }))
					return dispatch(
						apiRequest(ADMIN_GET_BANNED_ACCOUNTS, {}, true, ADMIN_BANNED_ACCOUNTS_LIST_MODULE_ID),
					)
				},
			},
		],
		listTitle: 'Banned accounts',
		endpointId: [ADMIN_GET_BANNED_ACCOUNTS],
		recordType: creatorData,
		maxTableWidth: 1100,
	},
}
