import providers from 'root/src/shared/constants/providers'
import { verificationStates } from 'root/src/server/modules/creators/verify/constants/verification.constants'

export const setVerificationStatePayloadSchema = {
	type: 'object',
	properties: {
		userId: {
			type: 'string',
		},
		platform: {
			type: 'string',
			enum: [providers.twitch, providers.youtube, providers.tiktok],
		},
		platformId: {
			type: 'string',
		},
		mnemonicId: {
			type: 'string',
		},
		email: {
			type: 'string',
		},
		state: {
			type: 'string',
			enum: Object.values(verificationStates),
		},
	},
	required: ['state'],
}
