import prop from 'ramda/src/prop'
import { KEYS_AND_CODES_FOR_CREATOR_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { creatorReward, dropKey } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { tableListType } from 'root/src/shared/constants/moduleTypes'
import { VIEW_PROJECT_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import pushRouteClient from 'root/src/client/logic/route/util/pushRouteClient'
import generalRecordModification from 'root/src/client/logic/api/actions/generalRecordModification'
import setDropDeliveredRewardsUsed from 'root/src/client/logic/api/thunks/setDropDeliveredRewardsUsed'
import { RewardEndpoint } from 'root/src/shared/rewards/constants'

export default {
	[KEYS_AND_CODES_FOR_CREATOR_MODULE_ID]: {
		moduleType: 'list',
		listType: tableListType,
		recordType: dropKey,
		endpointId: [RewardEndpoint.GET_REWARDS_FOR_CREATOR],
		defaultListDataLength: 10,
		isClientPaginated: true,
		listModuleId: KEYS_AND_CODES_FOR_CREATOR_MODULE_ID,
		constantListStoreKey: true,
		listTitle: 'Keys & Codes for Viewers',
		toggleVisibilityProp: [2],
		headerType: 'backgroundDesign',
		tableColumns: [
			['Name', 'name'],
			['Key/Code', 'rewardData'],
			['Created', 'created'],
			['Restrictions', 'restrictions'],
		],
		tableItemActions: [
			{
				name: prop('projectTitle'),
				fn: item => () => pushRouteClient({
					routeId: VIEW_PROJECT_ROUTE_ID,
					routeParams: { recordId: item.projectUrl },
				}),
				header: 'Source',
				order: 1,
			},
			{
				value: prop('rewardUsed'),
				fn: item => async (dispatch) => {
					const { rewardData, rewardUsed } = item
					dispatch(
						generalRecordModification({}, [
							{
								modification: 'set',
								path: [creatorReward, rewardData, 'rewardUsed'],
								value: rewardUsed ? !rewardUsed : true,
							},
						]),
					)
				},
				header: 'Key Used?',
				type: 'checkbox',
				centered: true,
			},
		],
		listActionButton: {
			label: 'Save all',
			onClickFn: setDropDeliveredRewardsUsed,
		},
	},
}
