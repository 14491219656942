// this ourUrl was designed for backend so it works only from lambda
import {
	isProdEnv,
} from 'root/src/shared/util/envSelect'

const mail = process.env.AWS_DEV_EMAIL || 'devtesting@daredrop.com'

export const ourUrl = process.env.RECORD_SET || 'localhost:8585'
export const urlProtocol = process.env.RECORD_SET ? 'https' : 'http'
export const logoSrc = `${ourUrl}/logo.png`
export const rulesOfUseUrl = `${ourUrl}/rules-of-use`
export const resetPasswordUrl = `${ourUrl}/reset-password`
export const marketplaceUrl = `${ourUrl}/`
export const paypalEmailUrl = `${ourUrl}/payout-method`
export const survey = 'forms.gle/iqnCmQurEtNRK8Xd8'
export const ourName = 'Dare Drop'
export const ourAddress = 'St. Helena, CA 94574'
export const ourCountry = 'United States'
export const ourPostBox = 'PO Box 2021'
export const ourEmail = isProdEnv ? 'support@daredrop.com' : mail
export const accountsEmail = isProdEnv ? 'accounts@daredrop.com' : process.env.ACCOUNTS_EMAIL ?? mail
export const ourSalesEmail = isProdEnv ? 'inboundsales@daredrop.com' : process.env.SALES_EMAIL ?? mail
export const ourFinanceEmail = isProdEnv ? 'finance@daredrop.com' : process.env.FINANCE_EMAIL ?? mail
export const ourAccountManagersEmail = isProdEnv ? 'accounts@daredrop.com' : process.env.ACCOUNTS_EMAIL ?? mail
export const newUsersEmail = isProdEnv ? 'newusers@daredrop.com' : process.env.NEW_USERS_EMAIL ?? mail
export const verifyAccountUrl = `${ourUrl}/verify-account`
export const createDare = `${ourUrl}/create-project`
export const unsubscribe = `${ourUrl}/unsubscribe`
export const adminBanPanel = `${ourUrl}/ban-admin`
export const myKeysUrl = `${ourUrl}/my-keys`
export const discordUrl = 'discord.gg/xmECwRu'
export const setupGuide = 'https://daredrop.com/blog/stream-setup-guide/'
export const bestPractices = 'https://daredrop.com/blog/best-practices-for-streamers-using-dare-drop/'
export const MAX_RECIPIENTS = 50
