import { awardPointsEndpoint } from 'root/src/server/modules/points/endpoints/awardPoints.endpoint';
import { addPaidOffPlatformPointsEndpoint } from 'root/src/server/modules/points/endpoints/addPaidOffPlatformPoints.endpoint';
import { initPointsPayoutEndpoint } from 'root/src/server/modules/points/endpoints/initPointsPayout.endpoint';
import { createPointsPayoutEndpoint } from 'root/src/server/modules/points/endpoints/createPointsPayout.endpoint';
import { createPointsApplicationEndpoint } from 'root/src/server/modules/points/endpoints/createPointsApplication.endpoint';
import { getPointsApplicationsEndpoint } from 'root/src/server/modules/points/endpoints/getPointsApplications.endpoint';
import { setPointsApplicationStateEndpoint } from 'root/src/server/modules/points/endpoints/setPointsApplicationState.endpoint';
import { checkExpiredPointsReservationsEndpoint } from 'root/src/server/modules/points/endpoints/checkExpiredPointsReservations.endpoint';
import { getCampaignTermsAcceptedEndpoint } from 'root/src/server/modules/points/endpoints/getCampaignTermsAccepted.endpoint';
import { listCampaignTermsAcceptedEndpoint } from 'root/src/server/modules/points/endpoints/listCampaignTermsAccepted.endpoint';
import { updateCampaignTermsAcceptedEndpoint } from 'root/src/server/modules/points/endpoints/updateCampaignTermsAccepted.endpoint';
export const pointsEndpoints = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, awardPointsEndpoint), addPaidOffPlatformPointsEndpoint), initPointsPayoutEndpoint), createPointsPayoutEndpoint), createPointsApplicationEndpoint), getPointsApplicationsEndpoint), setPointsApplicationStateEndpoint), checkExpiredPointsReservationsEndpoint), getCampaignTermsAcceptedEndpoint), listCampaignTermsAcceptedEndpoint), updateCampaignTermsAcceptedEndpoint);
